import axios from '@axios'

export default {
  namespaced: true,
  state: {
    kassSums: [],
    activeKassa: 0,
    settlements: [],
    activeSettle: 0,
    activeSettleClient: {},
    dateRange: [],
    dateRange2: []
  },
  getters: {},
  mutations: {
    UPDATE_KASSA_SUMMARY(state, val) {
      state.kassSums = val
    },
    UPDATE_ACTIVE_KASSA(state, val) {
      state.activeKassa = state.activeKassa === val ? null : val
    },
    UPDATE_SETTLE_SUMMARY(state, val) {
      state.settlements = val
    },
    UPDATE_ACTIVE_SETTLE(state, val) {
      state.activeSettle = state.activeSettle === val ? null : val
    },
    UPDATE_ACTIVE_SETTLE_CLIENT(state, val) {
      state.activeSettleClient = val
    },
    UPDATE_RANGE(state, val) {
      state.dateRange = val
    },
    UPDATE_RANGE2(state, val) {
      state.dateRange2 = val
    }
  },
  actions: {
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Clients', {
            _size: 1,
            _p: 1,
            _sort: '',
            _where: `(id,eq,${id})`,
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Clients'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSettleSub(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/SettleSub_view', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTransaction2(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Settlements_view', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTransaction(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Transactions_view', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSale(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getSale', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTransaction(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getTransaction', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTransaction(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/deleteTransaction', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSupliers() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Clients', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '(type,eq,customer)',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Clients'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivities() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Activities', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Activities'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCars() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Cars', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Cars'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWarehouses() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Warehouses', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Warehouses'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUsers() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Users', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Users'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProducts() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Products', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Products'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getSubOptions(ctx, activity) {
      let whereCond = ''
      if (activity && activity.subtype) {
        whereCond = `(type,eq,${activity.subtype})`
      }
      const { related_to } = activity
      const postData = {
        _size: 1000,
        _p: 1,
        _sort: '',
        _where: whereCond,
        _fields: '-',
        _tableconstruction_version: 'vertri',
        _zeTable: related_to
      }
      // console.log(related_to, activity, postData)
      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line camelcase
          .post(`/getAll/${related_to}`, postData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createSale(ctx, transactionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/createSale', transactionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateSale(ctx, transactionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/updateSale', transactionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addSupplier(ctx, supplierData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Clients/', supplierData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addTransaction(ctx, activityData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Transactions/', activityData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateTransaction(ctx, { id, transactionData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/updateRow/Transactions/${id}`, transactionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addActivity(ctx, activityData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Activities/', activityData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addCar(ctx, carData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Cars/', carData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProduct(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Products/', productData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFile(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/upload/', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addKassa(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Accounts', clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReceiver(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Receivers', clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchKassas() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Accounts', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Accounts'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  }
}
