<template>
  <b-sidebar
    id="add-new-account-sidebar"
    v-model="showRecSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :width="$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-account-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Add New Account') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            :name="t('Name')"
            rules="required"
          >
            <b-form-group
              :label="t('Name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="kassaData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="t('Responsible')"
            :rules="'required'"
          >
            <b-form-group
              :label="t('Responsible')"
              label-for="responsible"
              :state="getValidationState(validationContext)"
            >
              <v-select
                ref="refRespOpt"
                v-model="kassaData.resp_id"
                :state="getValidationState(validationContext)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="users"
                label="name"
                :reduce="val => val.id"
                input-id="responsible"
                :clearable="false"
              >
                <template #list-header>
                  <li
                    v-if="users.length === 0"
                    class="text-center text-primary my-2"
                  >
                    <b-spinner
                      class="align-middle mr-1"
                    />
                    <strong>Загрузка...</strong>
                  </li>
                </template>
                <template #selected-option="{ name, active }">
                  <b-avatar
                    size="26"
                    :text="avatarText(name)"
                    :variant="`light-${resolveUserAvatarVariant(active)}`"
                  />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}
                  </span>
                </template>
                <template #option="{ name, active }">
                  <b-avatar
                    size="26"
                    :text="avatarText(name)"
                    :variant="`light-${resolveUserAvatarVariant(active)}`"
                  />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}
                  </span>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="onSubmitProcess"
            >
              <b-spinner
                v-if="onSubmitProcess"
                small
                type="grow"
              />
              {{ t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :disabled="onSubmitProcess"
              @click="hide"
            >
              {{ t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,
    // BFormTextarea,
    // BMedia,
    BAvatar,
    // BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const toast = useToast()

    const blankKassaData = {
      name: '',
      resp_id: '',
    }

    const kassaData = ref(JSON.parse(JSON.stringify(blankKassaData)))
    const resetKassaData = () => {
      kassaData.value = JSON.parse(JSON.stringify(blankKassaData))
    }

    const onSubmitProcess = ref(false)
    const showRecSidebar = ref(false)
    const closeSidebar = () => {
      showRecSidebar.value = false
    }
    const onSubmit = () => {
      onSubmitProcess.value = true
      store.dispatch('app-transaction/addKassa', kassaData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Данные успешно сохранены',
            },
          })
          emit('refetch-data', kassaData.value)
          emit('update:is-add-new-account-sidebar-active', false)
          closeSidebar()
        })
        .finally(() => {
          onSubmitProcess.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetKassaData)

    const resolveUserAvatarVariant = status => {
      // if (status === 'New') return 'primary'
      // // if (status === 'Closed') return 'danger'
      // // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 1) return 'success'
      return 'danger'
    }

    const users = ref([])
    store.dispatch('app-transaction/fetchUsers')
      .then(response => {
        users.value = response.data.records.map(item => {
          const newItem = {
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            active: item.active,
          }
          return newItem
        })
      })

    return {
      kassaData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      onSubmitProcess,
      t,
      resolveUserAvatarVariant,
      avatarText,
      users,
      showRecSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
