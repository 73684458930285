<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="transactionData === undefined"
    >
      <h4 class="alert-heading">
        {{ t('Error fetching transaction data') }}
      </h4>
      <div class="alert-body">
        {{ t('No transaction found with this transaction id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          {{ t('Transaction List') }}
        </b-link>
        {{ t('for other transactions.') }}
      </div>
    </b-alert>
    <validation-observer
      v-show="!transactionDataLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmitSave)"
        @reset.prevent="resetForm"
      >
        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="10"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >

              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        swm
                      </h3>
                    </div>
                    <!-- <p class="card-text mb-25">
                      company name, address, itn
                    </p>
                    <p class="card-text mb-25">
                      bank account, etc
                    </p>
                    <p class="card-text mb-0">
                      +998946393966
                    </p> -->
                    <!-- <b-card-text class="mb-25">
                      Office 149, 450 South Brand Brooklyn
                    </b-card-text>
                    <b-card-text class="mb-25">
                      San Diego County, CA 91905, USA
                    </b-card-text>
                    <b-card-text class="mb-0">
                      +1 (123) 456 7891, +44 (876) 543 2198
                    </b-card-text> -->
                  </div>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="">
                        {{ t('Add transaction') }}
                      </h4>
                      <!-- <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="transactionData.id"
                          disabled
                        />
                      </b-input-group> -->
                    </div>
                    <!-- <div class="d-flex align-items-right justify-content-end mb-1">
                      <h4 class="invoice-title">
                        {{ t('Приход товара') }}
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="transactionData.id"
                          disabled
                        />
                      </b-input-group>
                    </div> -->
                    <div class="d-flex align-items-center justify-content-end">
                      <span class="title align-middle mb-1">
                        {{ t('Date') }}:
                      </span>
                      <validation-provider
                        #default="validationContext"
                        :name="t('Date')"
                        rules="required"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                          class="invoice-edit-input-group"
                        >
                          <flat-pickr
                            v-model="transactionData.date"
                            class="form-control invoice-edit-input"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <!-- <div class="d-flex align-items-center">
                      <span class="title">
                        Due Date:
                      </span>
                      <flat-pickr
                        v-model="transactionData.dueDate"
                        class="form-control invoice-edit-input"
                      />
                    </div> -->
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">

                  <b-col
                    cols="12"
                    xl="4"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Activity') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Activity')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <!-- Select activity -->
                        <v-select
                          ref="refActivityOpt"
                          v-model="transactionData.activity"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="activities"
                          label="name"
                          input-id="purchase-data-client"
                          :clearable="false"
                          @input="val => getSubOptions(val)"
                        >
                          <template #list-header>
                            <!-- <li
                              v-b-toggle.sidebar-purchase-add-new-warehouse
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new activity') }}</span>
                            </li> -->
                            <li
                              v-if="activities.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                                small
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ name, type }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ name }}</span>
                              <b-badge
                                v-if="type !== null"
                                pill
                                :variant="`light-${type === 'income' ? 'success' : type === 'expense' ? 'danger' : 'primary'}`"
                              >
                                <small>{{ t(type) }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template #option="{ name, type, defaults }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <div>
                                <span class="align-middle"> {{ name }}</span>
                                <b-badge
                                  v-if="defaults === '1'"
                                  pill
                                  :variant="`light-secondary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge>
                              </div>
                              <b-badge
                                v-if="type !== null"
                                pill
                                :variant="`light-${type === 'income' ? 'success' : type === 'expense' ? 'danger' : 'primary'}`"
                                class="mr-55"
                              >
                                <small>{{ t(type) }}</small>
                              </b-badge>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- Selected activity -->
                    <div
                      v-if="transactionData.activity"
                      class="mt-1"
                    >
                      <!-- <li class="d-flex justify-content-between align-items-end">
                        <b-badge
                          pill
                          :variant="`light-${resolveBalanceColor(Math.floor(Math.random()*15)*1000000-3000000)}`"
                        >
                          <small>{{ t('Ostatka') }}: </small>
                          {{ label }}
                          {{ `${Math.floor(Math.random()*15)*1000000-3000000} ${ t('t') }` }}
                        </b-badge>
                      </li> -->
                      <h6 class="mb-1">
                        {{ t('Description of activity') }}:
                      </h6>
                      <!--<table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              Product1:
                            </td>
                            <td><span class="font-weight-bold">110.55</span></td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Product2:
                            </td>
                            <td><span class="font-weight-bold">10.55</span></td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </b-col>

                  <!-- Col: Invoice To -->
                  <b-col
                    cols="12"
                    xl="4"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Related from') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Related from')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refSubOpt"
                          v-model="transactionData.from_sub"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="accounts"
                          label="itemTitle"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Select Item"
                        >
                          <template #list-header>
                            <li
                              v-b-toggle.add-new-account-sidebar
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add New Account') }}</span>
                            </li>
                            <li
                              v-if="accounts.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ name, balance }">
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ name }}</span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ balance }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template #option="{ name, balance }">
                            <li class="d-flex justify-content-between align-items-center">
                              <div class="align-items-center">
                                <span class="align-middle"> {{ name }}</span>
                                <!-- <b-badge
                                  v-if="product.id === 2"
                                  pill
                                  :variant="`light-primary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge> -->
                              </div>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                                class="pr-60"
                              >
                                {{ balance }}
                              </b-badge>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="4"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Related to') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Related to')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refSubOpt"
                          v-model="transactionData.to_sub"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="suboptions"
                          label="itemTitle"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Select Item"
                        >
                          <template
                            v-if="transactionData.activity && transactionData.activity.related_to === 'Accounts'"
                            #list-header
                          >
                            <li
                              v-b-toggle.sidebar-purchase-add-new-product
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new account') }}</span>
                            </li>
                            <li
                              v-if="suboptions.length === 0 && transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                            <li
                              v-else-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Users'"
                            #list-header
                          >
                            <li
                              v-b-toggle.add-new-user-sidebar
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new user') }}</span>
                            </li>
                            <li
                              v-if="suboptions.length === 0 && transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                            <li
                              v-else-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Clients'"
                            #list-header
                          >
                            <li
                              v-b-toggle.sidebar-purchase-add-new-supplier
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new client') }}</span>
                            </li>
                            <li
                              v-if="suboptions.length === 0 && transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                            <li
                              v-else-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-else
                            #list-header
                          >
                            <li
                              v-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-if="transactionData.activity && transactionData.activity.related_to === 'Accounts'"
                            #selected-option="{ name, balance }"
                          >
                            <!-- <feather-icon
                              :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                              size="16"
                              class="align-middle mr-50"
                            /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50"> {{ name }}</span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ roundUp(balance) }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Clients'"
                            #selected-option="{ firstname, surname, balance }"
                          >
                            <!-- <feather-icon
                                :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                size="16"
                                class="align-middle mr-50"
                              /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50">
                                <b-avatar
                                  size="26"
                                  :text="avatarText(`${firstname} ${surname}`)"
                                  :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                                />
                                <span class="ml-50 d-inline-block align-middle">
                                  {{ `${firstname} ${surname}` }}
                                </span>
                              </span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ roundUp(balance) }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Users'"
                            #selected-option="{ firstName, lastName, active, balance }"
                          >
                            <!-- <feather-icon
                                :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                size="16"
                                class="align-middle mr-50"
                              /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50">
                                <b-avatar
                                  size="26"
                                  :text="avatarText(`${firstName} ${lastName}`)"
                                  :variant="`light-${resolveUserAvatarVariant(active)}`"
                                />
                                <span class="ml-50 d-inline-block align-middle">
                                  {{ `${firstName} ${lastName}` }}
                                </span>
                              </span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ balance }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else
                            #selected-option
                          >
                            <!-- <feather-icon
                                :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                size="16"
                                class="align-middle mr-50"
                              /> -->
                            <li
                              v-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                          <template
                            v-if="transactionData.activity && transactionData.activity.related_to === 'Accounts'"
                            #option="{ name, balance }"
                          >
                            <li class="d-flex justify-content-between align-items-center">
                              <div class="align-items-center">
                                <span class="align-middle"> {{ name }}</span>
                                <!-- <b-badge
                                  v-if="product.id === 2"
                                  pill
                                  :variant="`light-primary`"
                                >
                                  <small>{{ t('Default') }}</small>
                                </b-badge> -->
                              </div>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                                class="pr-60"
                              >
                                {{ balance }}
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Users'"
                            #option="{ firstName, lastName, active, balance }"
                          >
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50">
                                <b-avatar
                                  size="26"
                                  :text="avatarText(`${firstName} ${lastName}`)"
                                  :variant="`light-${resolveUserAvatarVariant(active)}`"
                                />
                                <span class="ml-50 d-inline-block align-middle">
                                  {{ `${firstName} ${lastName}` }}
                                </span>
                              </span>
                              <b-badge
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ balance }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else-if="transactionData.activity && transactionData.activity.related_to === 'Clients'"
                            #option="{ firstname, surname, balance }"
                          >
                            <!-- <feather-icon
                                :icon="resolveBatteryStatusVariantAndIcon(value).icon"
                                size="16"
                                class="align-middle mr-50"
                              /> -->
                            <li class="d-flex justify-content-between align-items-center">
                              <span class="align-middle mr-50">
                                <b-avatar
                                  size="26"
                                  :text="avatarText(`${firstname} ${surname}`)"
                                  :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                                />
                                <span class="ml-50 d-inline-block align-middle">
                                  {{ `${firstname} ${surname}` }}
                                </span>
                              </span>
                              <b-badge
                                v-if="balance"
                                pill
                                :variant="`light-${balance > 0 ? 'success' : balance < 0 ? 'danger' : 'primary'}`"
                              >
                                <small>{{ balance }}</small>
                              </b-badge>
                            </li>
                          </template>
                          <template
                            v-else
                            #option
                          >
                            <li
                              v-if="!transactionData.activity"
                              class="text-center text-primary my-2"
                            >
                              <strong>Please select activity first...</strong>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="4"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Received') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Received')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refRespOpt"
                          v-model="transactionData.rec_user_id"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="receivers"
                          label="name"
                          input-id="purchase-data-rec_user_id"
                          :clearable="false"
                        >
                          <template #list-header>
                            <li
                              v-b-toggle.add-new-rec-sidebar
                              class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add new receiver') }}</span>
                            </li>
                            <li
                              v-if="users.length === 0"
                              class="text-center text-primary my-2"
                            >
                              <b-spinner
                                class="align-middle mr-1"
                              />
                              <strong>Загрузка...</strong>
                            </li>
                          </template>
                          <template #selected-option="{ name }">
                            <b-avatar
                              size="26"
                              :text="avatarText(name)"
                            />
                            <span class="ml-50 d-inline-block align-middle">
                              {{ name }}
                            </span>
                          </template>
                          <template #option="{ name }">
                            <b-avatar
                              size="26"
                              :text="avatarText(name)"
                            />
                            <span class="ml-50 d-inline-block align-middle">
                              {{ name }}
                            </span>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                  </b-col>

                  <b-col
                    cols="12"
                    xl="4"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Currency') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Currency')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refCurrency"
                          v-model="transactionData.currency"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['USD','UZS']"
                          input-id="purchase-data-currency"
                          :clearable="false"
                        >
                        <!-- <template #list-header>
                          <li
                            v-if="users.length === 0"
                            class="text-center text-primary my-2"
                          >
                            <b-spinner
                              class="align-middle mr-1"
                            />
                            <strong>Загрузка...</strong>
                          </li>
                        </template> -->
                        <!-- <template #selected-option="{ name, email, active }">
                          <b-avatar
                            size="26"
                            :text="avatarText(name)"
                            :variant="`light-${resolveUserAvatarVariant(active)}`"
                          />
                          <span class="ml-50 d-inline-block align-middle">
                            {{ name }}
                          </span>
                        </template>
                        <template #option="{ name, email, active }">
                          <b-avatar
                            size="26"
                            :text="avatarText(name)"
                            :variant="`light-${resolveUserAvatarVariant(active)}`"
                          />
                          <span class="ml-50 d-inline-block align-middle">
                            {{ name }}
                          </span>
                        </template> -->
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="4"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Payment Type') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Payment Type')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          ref="refPayment_type"
                          v-model="transactionData.payment_type"
                          :state="getValidationState(validationContext)"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Нал.','Безнал']"
                          input-id="purchase-data-payment_type"
                          :clearable="false"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="4"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Amount') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Amount')"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="transactionData.amount"
                          :state="getValidationState(validationContext)"
                          type="number"
                          class="mb-2"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="transactionData.currency !== 'USD'"
                    cols="12"
                    xl="2"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Rate') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Rate')"
                      :rules="transactionData.currency !== 'USD' ? 'required' : ''"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="transactionData.rate"
                          :state="getValidationState(validationContext)"
                          type="number"
                          class="mb-2"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="transactionData.currency !== 'USD'"
                    cols="12"
                    xl="2"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('USD') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('USD')"
                      :rules="transactionData.currency !== 'USD' ? 'required' : ''"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="transactionData.usd"
                          :state="getValidationState(validationContext)"
                          type="number"
                          class="mb-2"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="transactionData.currency !== 'USD'"
                    cols="12"
                    xl="2"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      {{ t('Commision') }}:
                    </h6>

                    <validation-provider
                      #default="validationContext"
                      :name="t('Commision')"
                      :rules="transactionData.currency !== 'USD' ? 'required' : ''"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="transactionData.commision"
                          :state="getValidationState(validationContext)"
                          type="number"
                          class="mb-2"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">{{ t('Note') }}: </span>
                <b-form-textarea v-model="transactionData.comments" />
              </b-card-body>
            </b-card>

          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="2"
            class="invoice-actions mt-md-0 mt-2"
          >

            <!-- Action Buttons -->
            <b-card>

              <!-- Button: Send Invoice -->
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                :to="{ name: 'apps-purchase-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  class="mx-auto"
                  size="18"
                />
                {{ t('Back to list') }}
              </b-button> -->

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                @click.prevent="handleSubmit(onSubmitSave)"
              >
                <b-spinner
                  v-if="submitOnProcess.save"
                  small
                  type="grow"
                />
                {{ t('Update') }}
              </b-button>

              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                @click.prevent="handleSubmit(onSubmitSaveSell)"
              >
                <b-spinner
                  v-if="submitOnProcess.saveandsell"
                  small
                />
                {{ t('Save and sell') }}
              </b-button> -->

              <!-- Button: Draft -->
              <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mb-75"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                @click.prevent="handleSubmit(onSubmitToDraft)"
              >
                <b-spinner
                  v-if="submitOnProcess.todraft"
                  small
                  type="grow"
                />
                {{ t('To draft') }}
              </b-button> -->

              <!-- Button: Cancel -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                block
                :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
                :to="{ name: 'apps-transaction-list'}"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-card>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div
      v-show="transactionDataLoading"
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>{{ t('Загрузка') }}...</strong>
    </div>
    <transaction-sidebar-add-new-suplier
      @refetch-data="refetchSuppliers"
    />
    <transaction-sidebar-add-new-warehouse
      @refetch-data="refetchActivities"
    />
    <transaction-sidebar-add-new-product
      @refetch-data="refetchSubOptions"
    />
    <transaction-sidebar-add-new-kassa
      @refetch-data="refetchKassas"
    />
    <transaction-sidebar-add-new-rec
      @refetch-data="refetchRec"
    />
    <user-list-add-new
      v-if="transactionData.activity && transactionData.activity.related_to === 'Users'"
      is-add-new-user-sidebar-sub
      @refetch-data="refetchSubOpt"
    />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import router from '@/router'
import {
  ref, onUnmounted, watchEffect, watch, computed,
} from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Validation
import {
  required, alphaNum, email, length, min, max_value,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardBody, BButton, BForm, BFormInput, BFormInvalidFeedback, BFormGroup, BFormTextarea, VBToggle, BSpinner, BBadge, BAlert, BLink, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import transactionStoreModule from '../transactionStoreModule'
import TransactionSidebarAddNewSuplier from '../TransactionSidebarAddNewSuplier.vue'
import TransactionSidebarAddNewWarehouse from '../TransactionSidebarAddNewWarehouse.vue'
import TransactionSidebarAddNewProduct from '../TransactionSidebarAddNewProduct.vue'
import TransactionSidebarAddNewKassa from '../TransactionSidebarAddNewKassa.vue'
import TransactionSidebarAddNewRec from '../TransactionSidebarAddNewRec.vue'
import UserListAddNew from '../../user/users-list/UserListAddNew.vue'
import userStoreModule from '../../user/userStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    // BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // BInputGroup,
    // BInputGroupPrepend,
    BFormTextarea,
    // BFormCheckbox,
    BSpinner,
    // BPopover,
    BBadge,
    flatPickr,
    vSelect,
    Logo,
    TransactionSidebarAddNewSuplier,
    TransactionSidebarAddNewWarehouse,
    TransactionSidebarAddNewProduct,
    TransactionSidebarAddNewKassa,
    TransactionSidebarAddNewRec,
    UserListAddNew,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // BFormFile,
    BAlert,
    BLink,
    // BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      alphaNum,
      email,
      length,
      min,
      max_value,
    }
  },
  watch: {
    transactionData(value) {
      console.log('pd updated', value)
      // console.log(this.$refs.refFile)
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.transactionData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    changeHeightInItemForm() {
      this.$refs.form.style.overflow = 'hidden'

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.transactionData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
  },
  setup() {
    const toast = useToast()
    const TRANSACTION_APP_STORE_MODULE_NAME = 'app-transaction'
    const { t } = useI18nUtils()
    const totalWeightActivator = ref(false)
    const refRespOpt = ref(null)

    // Register module
    if (!store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.registerModule(TRANSACTION_APP_STORE_MODULE_NAME, transactionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.unregisterModule(TRANSACTION_APP_STORE_MODULE_NAME)
    })

    const users = ref([])
    store.dispatch('app-transaction/fetchUsers')
      .then(response => {
        users.value = response.data.records.map(item => {
          const newItem = {
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            active: item.active,
          }
          return newItem
        })
      })

    const itemFormBlankItem = {
      product: null,
      cost: 0,
      quantity: 0,
      weight: 0,
      subItems: [
        {
          weight: 0,
        },
      ],
      description: '',
    }

    const blanktransactionData = {
      date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' '),
      activity: null,
      from_sub: null,
      to_sub: null,
      amount: 0,
      currency: 'USD',
      rec_user_id: null,
      payment_type: 'Нал.',
      commision: 100,
      rate: 1,
      usd: 0,
      comments: '',
    }

    const transactionData = ref({
      date: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 10).replace('T', ' '),
      activity: null,
      from_sub: null,
      to_sub: null,
      amount: 0,
      currency: 'USD',
      rec_user_id: null,
      payment_type: 'Нал.',
      commision: 100,
      rate: 1,
      usd: 0,
      comments: '',
    })

    // const transactionData = ref({})

    const activities = ref([])
    store.dispatch('app-transaction/fetchActivities')
      .then(response => {
        activities.value = response.data.records
        return response.data.records
      })

    const suppliers = ref([])
    store.dispatch('app-transaction/fetchSupliers')
      .then(response => {
        suppliers.value = response.data.records
        return response.data.records
      })

    const resettransactionData = () => {
      transactionData.value = JSON.parse(JSON.stringify(blanktransactionData))
    }

    const suboptions = ref([])
    const getSubOptions = async activity => {
      suboptions.value = await store.dispatch('app-transaction/getSubOptions', activity)
        .then(response => {
          transactionData.value.to_sub = null
          return response.data.records
        })
    }
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const accounts = ref([])

    store.dispatch('app-transaction/getSubOptions', { related_to: 'Accounts' })
      .then(response => {
        accounts.value = response.data.records
      })

    const receivers = ref([])

    store.dispatch('app-transaction/getSubOptions', { related_to: 'Receivers' })
      .then(response => {
        receivers.value = response.data.records
      })

    const relatedto = computed(() => router.currentRoute.params.target)

    watch(() => relatedto.value, target => {
      console.log(target)
      if (target != null) {
        //
      }
    })

    const transactionDataLoading = ref(false)
    if (router.currentRoute.params.id) {
      transactionDataLoading.value = true
      try {
        store.dispatch('app-transaction/getTransaction', { id: router.currentRoute.params.id })
          .then(async response => {
            const item = response.data
            transactionData.value = item
            await getSubOptions(item.activity)
            transactionData.value.to_sub = suboptions.value.find(sub => sub.id === item.to_sub_id)
            console.log(transactionData.value, suboptions.value)
          })
          .finally(() => {
            transactionDataLoading.value = false
          })
      } catch (e) {
        transactionDataLoading.value = false
      }
    }

    const updateSubItemQty = (index, val) => {
      // console.log(index, val, transactionData.value.items[index].subItems)
      const arr = []
      for (let i = 0; i < val; i = 1 + i) {
        arr.push({ weight: 0 })
      }
      transactionData.value.items[index].subItems = arr
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resettransactionData)

    watchEffect(() => console.log('router.currentRoute.params.target', router.currentRoute.params.target))
    watchEffect(() => console.log('suboptions.value', suboptions.value))

    const refSupplierOpt = ref(null)
    const refActivityOpt = ref(null)
    // const refKassaOpt = ref(null)
    const refCarOpt = ref(null)
    const refSubOpt = ref(null)

    const refetchKassas = val => {
      accounts.value.unshift(val)
      // refKassaOpt.value.$emit('input', val)
    }
    const refetchRec = val => {
      receivers.value.unshift(val)
      // refKassaOpt.value.$emit('input', val)
    }
    const refetchSubOpt = val => {
      console.log('refetchSubOpt', val)
      suboptions.value.unshift(val)
      // refKassaOpt.value.$emit('input', val)
    }

    const refetchSuppliers = val => {
      suppliers.value.unshift(val)
      refSupplierOpt.value.$emit('input', val)
      // transactionData.value.supplier = val
      // refSupplierOpt.value.$emit('option:selected', val)
      // setTimeout(() => {
      // refSupplierOpt.value.$emit('close')
      // }, 1000)
      // this.$nextTick(() => {
      //   refSupplierOpt.value.close()
      // })

      // store.dispatch('app-transaction/fetchSupliers')
      //   .then(response => { suppliers.value = response.data.records })
    }

    const refetchActivities = val => {
      activities.value.unshift(val)
      refActivityOpt.value.$emit('input', val)
      // store.dispatch('app-transaction/fetchActivities')
      //   .then(response => { activities.value = response.data.records })
    }

    const refetchSubOptions = val => {
      const newVal = {
        product: val,
        itemTitle: `${val.code} ${val.name}`,
        cost: val.init_price,
        quantity: val.quantity,
        weight: 0,
        subItems: [
          {
            weight: 0,
          },
        ],
        description: val.description,
      }
      suboptions.value.unshift(newVal)
      // refSubOpt.value.$emit('input', newVal)

      // store.dispatch('app-transaction/fetchSubOptions')
      //   .then(response => {
      //     suboptions.value = response.data.records
      //       .map(item => {
      //         const newItem = {
      //           product: item,
      //           itemTitle: item.name,
      //           cost: item.init_price,
      //           quantity: item.quantity,
      //           weight: 0,
      //           subItems: [
      //             {
      //               weight: 0,
      //             },
      //           ],
      //           description: item.description,
      //         }
      //         return newItem
      //       })
      //   })
    }

    const calcExchange = () => {
      transactionData.value.usd = ((transactionData.value.commision / 100) * transactionData.value.amount) / transactionData.value.rate
    }

    // const calcFromExchange = () => {
    //   transactionData.value.amount = transactionData.value.usd * transactionData.value.rate
    // }

    watch(() => [transactionData.value.amount, transactionData.value.rate, transactionData.value.commision], ([amount, rate, commision]) => {
      console.log(amount, rate, commision)
      calcExchange()
    })

    // watch(() => transactionData.value.rate, rate => {
    //   console.log(rate)
    //   calcExchange()
    // })

    // watch(() => transactionData.value.usd, usd => {
    //   console.log(usd)
    //   calcFromExchange()
    // })

    const submitOnProcess = ref({
      save: false,
      saveandsell: false,
      todraft: false,
    })
    const onSubmitSave = () => {
      submitOnProcess.value.save = true
      // console.log('transactionData.value.file', transactionData.value.file)
      const tid = router.currentRoute.params.id
      const payload = {
        date: transactionData.value.date,
        activity_id: transactionData.value.activity.id,
        from_sub_id: transactionData.value.from_sub ? transactionData.value.from_sub.id : null,
        to_sub_id: transactionData.value.to_sub.id,
        amount: transactionData.value.amount,
        currency: transactionData.value.currency,
        rate: transactionData.value.rate,
        commision: transactionData.value.commision,
        rec_user_id: transactionData.value.rec_user_id?.id,
        comments: transactionData.value.comments,
        type: transactionData.value.payment_type,
        status: 'Pending',
      }
      // payload.file = transactionData.value.file
      console.log('payload', payload)
      store.dispatch('app-transaction/updateTransaction', { id: tid, transactionData: payload })
        .then(response => {
          console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          router.push({ name: 'apps-transaction-list' })
          // resetForm()
          // if (transactionData.value.onCredit) router.push({ name: 'apps-transaction-list', params: { id: response.data.sale_id } })
          // else router.push({ name: 'apps-transactions-list', params: { id: response.data.sale_id } })
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.save = false
        })
    }

    // // const onSubmitSaveSell = () => {
    //   submitOnProcess.value.saveandsell = true
    //   const payload = JSON.parse(JSON.stringify(transactionData.value))
    //   payload.actiontype = 'saveandsell'
    //   payload.status = 'New'
    //   // payload.supplier = payload.supplier.contact
    //   // payload.activity = payload.activity.contact
    //   // payload.car = payload.car.contact
    //   // payload.items = payload.items.map( item => {
    //   //   item.product = item.product.contact
    //   // })

    //   payload.supplier = payload.supplier.id
    //   payload.activity = payload.activity.id
    //   payload.car = payload.car.id ? payload.car.id : payload.car
    //   payload.items = payload.items.map(item => {
    //     const newItem = item
    //     newItem.product = item.product.product.id
    //     return newItem
    //   })
    //   // console.log(payload)
    //   // payload.file = transactionData.value.file
    //   store.dispatch('app-transaction/createSale', payload)
    //     .then(response => {
    //       console.log(response)
    //       // const { records } = response.data
    //       // if (records) {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Успешно',
    //           icon: 'CheckCircleIcon',
    //           variant: 'success',
    //           text: 'Вы успешно нажали на кнопку сохранить.',
    //         },
    //       })
    //       resetForm()
    //       router.push({ name: 'apps-transaction-preview', params: { id: response.data.sale_id } })
    //       // }
    //     })
    //     .catch(error => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Error adding new purchase',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //           text: error.message,
    //         },
    //       })
    //     })
    //     .finally(() => {
    //       submitOnProcess.value.saveandsell = false
    //     })
    // }

    const onSubmitToDraft = () => {
      submitOnProcess.value.todraft = true
      const payload = JSON.parse(JSON.stringify(transactionData.value))
      payload.actiontype = 'todraft'
      payload.status = 'Draft'
      payload.order_id = router.currentRoute.params.id
      // payload.supplier = payload.supplier.contact
      // payload.activity = payload.activity.contact
      // payload.car = payload.car.contact
      // payload.items = payload.items.map( item => {
      //   item.product = item.product.contact
      // })

      payload.supplier = payload.supplier.id
      payload.activity = payload.activity.id
      payload.car = payload.car.id ? payload.car.id : payload.car
      payload.responsible = payload.responsible.id
      payload.items = payload.items.map(item => {
        const newItem = item
        newItem.product = item.product.product.id
        return newItem
      })
      // payload.file = transactionData.value.file
      console.log(payload)
      store.dispatch('app-transaction/createSale', payload)
        .then(response => {
          console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          router.push({ name: 'apps-transaction-preview', params: { id: response.data.sale_id } })
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.todraft = false
        })
    }

    const onSubmitWeight = index => {
      if (totalWeightActivator.value === false) {
        transactionData.value.items[index].weight = transactionData.value.items[index].subItems.reduce((total, item) => total + parseFloat(item.weight), 0)
      }
    }

    const paymentMethods = [
      'Bank Account',
      'Uzcard',
      'Cash',
    ]

    const resolveBalanceColor = value => {
      if (value > 0) return 'success'
      if (value === 0) return 'primary'
      if (value < 0) return 'danger'
      return 'secondary'
    }

    const totalPrice = computed(() => transactionData.value)

    // const fileSubmit = val => {
    //   const formData = new FormData()
    //   formData.append('file', val)
    //   store.dispatch('app-transaction/addFile', formData)
    //     .catch(error => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Error adding new purchase',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //           text: error.message,
    //         },
    //       })
    //     })
    // }

    const resolveUserAvatarVariant = status => {
      // if (status === 'New') return 'primary'
      // // if (status === 'Closed') return 'danger'
      // // if (status === 'Downloaded') return 'secondary'
      // if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 1) return 'success'
      return 'danger'
    }
    const roundUp = num => {
      const x = num ? Math.round(num * 10) / 10 : 0
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    }

    return {
      roundUp,
      transactionData,
      suppliers,
      activities,
      users,
      suboptions,
      updateSubItemQty,
      itemFormBlankItem,
      paymentMethods,
      totalWeightActivator,
      t,
      onSubmitWeight,
      onSubmitSave,
      // onSubmitSaveSell,
      onSubmitToDraft,
      submitOnProcess,
      refFormObserver,
      getValidationState,
      resetForm,
      refetchSuppliers,
      refetchActivities,
      refetchSubOptions,
      refSupplierOpt,
      refActivityOpt,
      refCarOpt,
      refSubOpt,
      resolveBalanceColor,
      totalPrice,
      refRespOpt,
      // fileSubmit,
      transactionDataLoading,
      resolveUserAvatarVariant,
      avatarText,
      getSubOptions,
      accounts,
      refetchKassas,
      receivers,
      refetchRec,
      refetchSubOpt,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
